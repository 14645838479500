import Vue from "vue";
import { Quill } from "vue2-editor";
import Component from "vue-class-component";
import { Notification } from "@/models/notification.interface";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
import moment from "moment";

Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);

@Component({})
export class EditorImages extends Vue {
  editorSettings = {
    modules: {
      imageDropAndPaste: {
        handler: this.customHanlder,
      },
    },
  };

  customHanlder(imageDataUrl, type, imageData) {
    const fileToSave = this.renameFileForEditorPasteAndDrop(
      imageData,
      type.split("/")[1]
    );

    this.$store
      .dispatch("system/addEditorImage", fileToSave.file)
      .then((response) => {
        const editor: any = this.$refs.magicEditor;
        const quillEditor = editor.$data.quill;
        let index = (quillEditor.getSelection() || {}).index;
        if (index === undefined || index < 0) index = quillEditor.getLength();
        quillEditor.insertEmbed(index, "image", response.url, "user");
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.edi-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  renameFileForEditorPasteAndDrop(file, type) {
    const newFile = new FormData();
    const filename = "EDITOR-" + moment().unix() + "." + type;
    file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);

    newFile.append("EDITOR", this.dataURItoBlob(file.dataUrl), filename);
    return { file: newFile, name: filename };
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  handleEditorImage(file, Editor, cursorLocation, resetUploader) {
    const data = new FormData();
    data.append("EDITOR", file);

    this.$store
      .dispatch("system/addEditorImage", data)
      .then((result) => {
        const url = result.url;
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.edi-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  handleRemoveEditorImage(image, clear = false) {
    if (!clear) {
      const text_array = image.split("/");
      const file_name = text_array[text_array.length - 1];

      this.$store.dispatch("system/removeEditorImage", file_name).catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.edi-e2"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    }
  }
}
